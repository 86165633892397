import React from "react";

export function HeaderTitle(props){
    const style = {
        color: props.color ??'#29c467',
        textAlign: 'center',
        marginBottom:20,
        fontSize: props.fontSize ?? 22,
        ...props.style
    }
    return (
        <div style={style}>
            {props.title}
        </div>
    )
}