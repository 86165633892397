import React from "react";
import Page from "./Page";

const NotFoundPage: React.FC = () =>{
    return(
        <Page name={"Page not found"}>
            404: Page not found. Sorry about that. It's probably still being built by our
            wonder demon engineers.
            <br/> <br/>
            Either that or you've ticked them off, and they've intentionally foiled you request...
        </Page>
    )
}


export default NotFoundPage;