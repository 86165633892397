import {IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonChip, IonLabel, IonList} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {HeaderTitle} from "../../layout/HeaderTitle";
import {TextPost} from "../../layout/TextPost";
import Page from "../Page";

const cardStyle = {
    width: '96%',
    margin: '0 2vw',
    height: '100%',
    minHeight:'85vh',
    display: 'inline-block',
    textAlign: 'center',
    overflowY: 'auto'

}

const picStyle = {
    width:'80vw',
    maxWidth: 525,
    height:'33vh',
    maxHeight: 400,
    margin: '0 auto'
}

export function DemonInfoCard(props){
    const urlParams = useParams()
    const [text, loadText] = useState("")

    let activeDemon = {}
    if(props.manifest.demons){
        props.manifest.demons.forEach(demon =>{
            if (demon.urlName === urlParams.demon){
                activeDemon = demon
                fetch(demon.textUrl)
                    .then(post => post.text())
                    .then(loadText)
            }
        })
    }



    if(!props.manifest.demons) return  <IonCard style = {cardStyle} color="light" />

    return (
        <Page name={"Demon Database"}>
            <IonCard style = {cardStyle} color="light" >
                <IonCardContent style = {{overflowY: 'auto'}}>
                    <img src={activeDemon.imageUrl} style = {picStyle} alt={''}/>
                    <br/>
                    <IonChip color = "danger">
                        <IonLabel>{activeDemon.power}</IonLabel>
                    </IonChip>
                    <IonChip color="warning">
                        <IonLabel>{activeDemon.type}</IonLabel>
                    </IonChip>
                    <br/><br/>
                    <HeaderTitle title = {activeDemon.name}/>
                    <TextPost text = {text}/>



                </IonCardContent>


            </IonCard>
        </Page>


    )
}