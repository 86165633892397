import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonNote,
} from '@ionic/react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  bulbOutline,
  bulbSharp,
  libraryOutline,
  librarySharp,
  storefrontOutline,
  storefrontSharp,
  skullOutline,
  skullSharp
} from 'ionicons/icons';

import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

export const appPages: AppPage[] = [
  {
    title: 'About',
    url: '/about',
    iosIcon: bulbOutline,
    mdIcon: bulbSharp
  },
  {
    title: 'Tales',
    url: '/tales',
    iosIcon: libraryOutline,
    mdIcon: librarySharp
  },{
    title: 'Demons',
    url: '/demons',
    iosIcon: skullOutline,
    mdIcon: skullOutline
  },
  {
    title: 'Store',
    url: '/store',
    iosIcon: storefrontOutline,
    mdIcon: storefrontSharp
  }

];

const Menu: React.FC = () => {

  const overlayImgStyle = {
    height:150,
    marginTop: -20,
    zIndex:10000,
  }
  return (
      <IonMenu contentId="main" menuId="main" type="overlay" style = {{maxHeight:"89vh"}}>
        <IonContent style = {{maxHeight:"89vh"}}>
          <IonList>
            <div style = {{display: "inline-block"}}>
              <img src={"/assets/icon/quantum-rip.png"}
                   alt={"hi-mom"}
                   style = {overlayImgStyle}/>
            </div>
            <div style = {{display: "inline-block", verticalAlign: 'top',   width: 125}}>
                <IonNote style = {{fontSize: 20}}>
                  Tales From The Quantum Rip
                </IonNote>

            </div>

            {appPages.map((appPage) => <MenuItem page ={appPage}/>)}
          </IonList>

        </IonContent>
      </IonMenu>
  );
};


const MenuItem: React.FC<{page: AppPage}> = ({page}) => {
  const location = useLocation();
  return (
      <IonItem className={location.pathname === page.url ? 'selected' : ''}
               routerLink={page.url}
               routerDirection="none"
               lines="none"
               detail={false}>

        <IonIcon slot="start"
                 ios={page.iosIcon}
                 md={page.mdIcon} />

        <IonLabel>{page.title}</IonLabel>
      </IonItem>
  );
}
export default Menu;
