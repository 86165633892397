import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import {HeaderTitle} from "../layout/HeaderTitle";

const Page = ({name, children}) => {

    let contentStyle = {
        margin: '0 auto',
        maxWidth: 600,
        padding: "0 15px 75px 15px"
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <HeaderTitle title={name} style = {{marginLeft: -30, marginTop:15}}/>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <div style = {contentStyle}>
                    <div style = {{margin:10}}>
                        {children}
                    </div>

                </div>
            </IonContent>
        </IonPage>
    );
};

export default Page;
