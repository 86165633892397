
import React from 'react';
import { IonApp} from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';

/* Core CSS required for Ionic layout to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Page Components */
import {TabRouter} from "./layout/TabRouter";
import Menu from './layout/Menu';

const App = () => {

    return (
        <IonApp>
            <IonReactHashRouter>
                <Menu />
                <TabRouter/>
            </IonReactHashRouter>
        </IonApp>
    );
};

export default App;
