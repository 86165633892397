import {
    IonBadge,
    IonButton, IonContent, IonFab, IonFabButton,
    IonIcon,
    IonImg,
    IonLabel, IonMenu, IonMenuButton, IonMenuToggle,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs
} from "@ionic/react";
import {calendar, informationCircle, personCircle, map} from "ionicons/icons";
import {Redirect, Route} from "react-router-dom";
import AboutPage from "../pages/AboutPage";
import NotFoundPage from "../pages/NotFoundPage";
import React, {useEffect, useState} from "react";
import {appPages} from "./Menu";
import {SeriesList} from "../pages/tales/SeriesList";
import {ChapterList} from "../pages/tales/ChapterList";
import {SeriesPost} from "../pages/tales/SeriesPost";
import {DemonGrid} from "../pages/demons/DemonGrid";
import {DemonInfoCard} from "../pages/demons/DemonInfoCard";

export function TabRouter(){
    const tabs = []

    appPages.forEach((page, index) =>{
        if (index > 3) return;
        else tabs.push(
            <IonTabButton tab={page.title} href={page.url}>
                <IonIcon ios={page.iosIcon}
                         md={page.mdIcon}/>
                <IonLabel>{page.title}</IonLabel>
            </IonTabButton>
        )
    })

    //empty tab for cool pic overlay
    tabs.splice(2, 0,
        <IonTabButton>
            <IonMenuToggle>
                <img src={"/assets/icon/quantum-portal.png"}
                     alt={"hi-mom"}
                     style={{width:65}}/>
            </IonMenuToggle>


        </IonTabButton>
    );

    const [postManifest, loadPosts] = useState({})
    useEffect(() =>{
        fetch("/tales/post-manifest.json")
            .then(manifest => manifest.json())
            .then(loadPosts)
    }, [])

    const [demonManifest, loadDemons] = useState({})
    useEffect(()=>{
        fetch('/demons/demon-manifest.json')
            .then(demons=>demons.json())
            .then(loadDemons)
    }, [])

    return (
        <React.Fragment>
            <IonTabs>
                <IonRouterOutlet id="main">
                    <Route path = "/about" component={AboutPage}/>
                    <Route path = "/tales" component={()=> <SeriesList manifest={postManifest}/>} />
                    <Route path = "/tales/:series/" component={()=> <ChapterList manifest={postManifest}/>}/>
                    <Route path = "/tales/:series/:chapter" component={()=> <SeriesPost manifest={postManifest}/>}/>
                    <Route path = "/demons/" component={()=> <DemonGrid manifest={demonManifest}/>}/>
                    <Route path = "/demons/:demon" component={()=> <DemonInfoCard manifest={demonManifest}/>}/>

                    <Route component={NotFoundPage}/>

                    <Redirect exact from="/" to="/tales"/>
                </IonRouterOutlet>

                <IonTabBar slot="bottom">
                    {tabs}
                </IonTabBar>

            </IonTabs>

        </React.Fragment>

    )
}
