import React from "react";

export function TextPost(props){
    const text = props.text.replace(/\n/g, '<br />')
    return (
        <div dangerouslySetInnerHTML={{ __html: text}}
             style={{textAlign:"left", margin: '10px auto', maxWidth: 600, paddingBottom:75, fontSize:"1.25em" }}
        />
    )

}