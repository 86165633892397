import React from "react";
import Page from "./Page";

const AboutPage: React.FC = () =>{
    return(
        <Page name={"About"}>
            <p style = {{textAlign: "left", margin: "20px"}}>
                Risky Chris is a computer programmer and mathematician by trade, but despite his technical tendencies, enjoys waxing poetic
                about science and the corporate hellscape of Earth. He spends his free time teaching under-privileged cats to talk.
                It is slow and difficult work, but he does have one prodigy student.
            </p>

        </Page>
    )
}


export default AboutPage;