import React from 'react'
import {IonItem, IonList, IonText} from "@ionic/react";
import Page from "../Page";
import {useParams} from "react-router";
import {useHashListener} from "../../util/component/hooks/HashListener";
import {HeaderTitle} from "../../layout/HeaderTitle";
import {HashSublink} from "../../util/functions/HashLink";

export function ChapterList(props){
    useHashListener()
    const urlParams = useParams()
    let series = [];
    let postLinks = []
    console.log(props.manifest)
    if (props.manifest.series){
        props.manifest.series.forEach((serial)=> {
            if (serial.urlName === urlParams.series) series = serial
        });

        postLinks = series.chapters.map(chapter =>{
            return(
                <ChapterItem chapter = {chapter}/>
            )
        })
    }


    return(
        <Page name = {series.name}>
            <IonList>
                {postLinks}
            </IonList>
        </Page>
    )
}

function ChapterItem(props){
    return(
        <IonItem href = {HashSublink(props.chapter.number)}>
            <IonText style = {{margin:'20px 0'}}>
                <div style = {{marginBottom:5}}>
                    Chapter {props.chapter.number}
                </div>
                {props.chapter.name}
            </IonText>
        </IonItem>
    )

}