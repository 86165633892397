import React from "react";
import Page from "../Page";
import {IonItem, IonList} from "@ionic/react";
import {useHashListener} from "../../util/component/hooks/HashListener";
import {HeaderTitle} from "../../layout/HeaderTitle";
import {HashSublink} from "../../util/functions/HashLink";

export function SeriesList(props){
    let postLinks = []
    useHashListener()
    if (props.manifest.series){
        postLinks = props.manifest.series.map(serial =>{
            return(
                <SeriesItem serial = {serial} href = {HashSublink(serial.urlName)}/>
            )
        })
    }

    return(
        <Page name = {"Tales From the Quantum Rip"}>
            <IonList>
                {postLinks}
            </IonList>
        </Page>
    )
}

function SeriesItem(props){
    return(
        <IonItem  href= {props.href}>
            {props.serial.name}
        </IonItem>
    )

}


