import React from "react";
import Page from "../Page";
import {IonCard, IonCardContent, IonCardSubtitle, IonItem, IonList, IonTitle} from "@ionic/react";
import {useHashListener} from "../../util/component/hooks/HashListener";
import {HashSublink} from "../../util/functions/HashLink";

export function DemonGrid(props){
    let demonCards = []
    useHashListener()
    if (props.manifest.demons){
        demonCards = props.manifest.demons.map(demon =>{
            return(
                <DemonCard demon ={demon}/>
            )
        })
    }

    return(
        <Page name={"Demon Database"}>
            <IonList style = {{minHeight:'80vh'}}>
                {demonCards}
            </IonList>
        </Page>
    )
}

function DemonCard(props){
    const cardStyle = {
        width: '10vw',
        height: '12vw',
        minWidth:110,
        minHeight:140,
        display: 'inline-block',
        margin: '0.25vw 1vw'
    }

    const picStyle = {
        width: '8vw',
        height: '8vw',
        minWidth:85,
        minHeight:85,
        borderRadius: 100,
        marginLeft: -7
    }



    return(
        <IonCard style = {cardStyle} color="light" href = {HashSublink(props.demon.urlName)}>
            <IonCardContent style = {{marginTop:-10}} >
                <img src={props.demon.thumbUrl} style = {picStyle} alt={''}/>
            </IonCardContent>

            <IonCardSubtitle style = {{textAlign: 'center'}}>{props.demon.name}</IonCardSubtitle>
        </IonCard>
    )

}


