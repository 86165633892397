import {useParams} from "react-router";
import Page from "../Page";
import React, {useEffect, useState} from "react";
import ReactPlayer from "react-player";
import useForceUpdate from "../../util/component/hooks/ForceUpdate";
import {useWindowSizeListener} from "../../util/component/hooks/WindowResizeListener";
import {HeaderTitle} from "../../layout/HeaderTitle";
import {TextPost} from "../../layout/TextPost";

export function SeriesPost(props){
    const urlParams = useParams()
    const [title, setTitle] = useState("")
    const [text, loadText] = useState("")
    const [video, loadVideo] = useState("")

    useEffect(()=> {
        if(!props.manifest.series) return

        props.manifest.series.forEach((serial)=> {
            if (serial.urlName === urlParams.series){
                let chapter = serial.chapters[urlParams.chapter-1]
                setTitle(`${serial.name} - ${chapter.name}`)
                loadVideo(chapter.videoUrl)
                fetch(chapter.textUrl)
                    .then(post => post.text())
                    .then(loadText)
            }
        });
    },[props.manifest])

    return(
        <Page>

            {video !== "" &&
            <VideoPost video ={video}/>
            }
            <HeaderTitle title ={title}/>
            <TextPost text = {text}/>

        </Page>
    )
}





function VideoPost(props){
    useForceUpdate()
    let [width, height] = useWindowSizeListener()
    const vidWidth = (width <= 600) ? width-50 : 550
    const vidHeight = (height <= 900) ? 200 : 335
    return(
        <ReactPlayer url={props.video} width = {vidWidth} height={vidHeight}/>
    )
}
